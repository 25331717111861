import Constants from 'expo-constants';
import * as Notifications from 'expo-notifications';
import React, { useState, useEffect, useRef, Component } from 'react';
import { Text, View, Button, Platform, SafeAreaView, StyleSheet, TouchableOpacity, ActivityIndicator, FlatList, Linking } from 'react-native';
import { WebView } from 'react-native-webview';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { NavigationContainer } from '@react-navigation/native';
import { useDrawerStatus } from '@react-navigation/drawer';
import WooCommerceAPI from 'react-native-woocommerce-api';
import {Image} from 'react-native' ; 
import { StatusBar } from 'expo-status-bar';


const joao = 45;
//var pega="";
let pegar="";



function HomeScreen({ navigation }) {

   const runFirst = `

     document.body.style.backgroundColor = 'yellow';
     setTimeout(function(){ window.alert('versão de teste, utilize o menu lateral puxando a lateral da tela') }, 2000);
      true; // note: this is required, or you'll sometimes get silent failures
     `;

  const [expoPushToken, setExpoPushToken] = useState('');
  const [notification, setNotification] = useState(false);
  const notificationListener = useRef();
  const responseListener = useRef();


 const webviewRef = useRef(null);

  const [canGoBack, setCanGoBack] = useState(false)
  const [canGoForward, setCanGoForward] = useState(false)
  const [currentUrl, setCurrentUrl] = useState('')

 

 const backButtonHandler = () => {
    if (webviewRef.current) webviewRef.current.goBack()
  }

const  frontButtonHandler = () => {
    if (webviewRef.current) webviewRef.current.goForward()
  }



const styles = StyleSheet.create({
  flexContainer: {
    flex: 1
  },
  tabBarContainer: {
    padding: 5,
    flexDirection: 'row',
    justifyContent: 'space-around',
    backgroundColor: '#b43757'
  },
  button: {
    color: 'white',
    fontSize: 18
  }
})






  return (
Platform.OS === 'web'?<iframe src='https://www2.multihost.com.br' height={800} id="ifr" base target="_blank" sandbox="allow-forms allow-modals allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts allow-top-navigation" />:<View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
<WebView
          source={{
            html: 'width="100%" height="50%" src="https://www2.multihost.com.br" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>',
          }} 

	  originWhitelist={['https://www2.multihost.com.br', 'git://*']}
          onMessage={(event) => {}}
          injectedJavaScript={runFirst}

 startInLoadingState={true}
 renderLoading={() => (
    <ActivityIndicator
      color='black'
      size='large'
      style={styles.flexContainer}
    />
  )}


ref={webviewRef}
          onNavigationStateChange={navState => {
            setCanGoBack(navState.canGoBack)
            setCanGoForward(navState.canGoForward)
            setCurrentUrl(navState.url)
          }}

onError={()=> navigation.navigate('Notifications')}

onHttpError={(syntheticEvent) => {
          const { nativeEvent } = syntheticEvent;
          console.warn(
          'Estou Offline preciso me conectar: ',
          nativeEvent.statusCode,
    );
  }}

  onRenderProcessGone={syntheticEvent => {
    const { nativeEvent } = syntheticEvent;
    console.warn(
      'WebView Crashed: ',
      nativeEvent.didCrash,
    );
  }}
/>

  <View style={styles.tabBarContainer}>
   	<Button
        title="📬"
        onPress={async () => {
     //     await schedulePushNotification();
          await navigation.toggleDrawer();
        }}
      /> 
	  <TouchableOpacity onPress={backButtonHandler}>
            <Text style={styles.button}>Back</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={frontButtonHandler}>
            <Text style={styles.button}>Forward</Text>
          </TouchableOpacity>
    </View>
      </View>

  );
}



function NotificationsScreen({ navigation }) {


  return (
   

 <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>


      <Button onPress={() => navigation.goBack()} title="Go back home" />
    </View>
  );
}



function ContactsScreen({ navigation }) {

  const initiateWhatsApp = () => {
   Linking.openURL('whatsapp://send?text=Preciso sua Ajuda...&phone=5511982391665');    
 };

  return (
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>

 <TouchableOpacity
          activeOpacity={0.7}
          style={styles.buttonStyle}
          onPress={initiateWhatsApp}>
          <Text style={styles.buttonTextStyle}>
            Send WhatsApp Message
          </Text>
        </TouchableOpacity>



  <Button onPress={() => navigation.goBack()} title="Go back home" />
    </View>
  );
}





function PromotionsScreen({ route,navigation }) 

{

 const { arrayItem, valor, valor1, itemId, itemCor } = route.params;



 return (


<View style={styles.container}>
     

     <Text> </Text>

	<Text>No {varia}</Text>
     	<Text>Nome {nome}</Text>
      <Image  style={{width: 150, height: 250}}   source={{uri:ima}}  		   resizeMode={'cover'} />
     	

     	<Text> </Text>
	<Image  style={{width: 50, height: 50}}   source={{uri:'https://www.multihost.com.br/logom.jpg'}}  resizeMode={'cover'} />

      <Button onPress={() => navigation.goBack()} title="Go back home" />





</View>




  );



}


const styles = StyleSheet.create({
  container: {
flex: 1,
justifyContent: 'center',
alignItems: 'center',
 marginTop:40
  },

});


const Drawer = createDrawerNavigator();

var nome = "";
var varia="";
var ima = "";


var nome1 = "";
var varia1="";
var ima1 = "";


var nome2 = "";
var varia2="";
var ima2 = "";


var nome3 = "";
var varia3="";
var ima3 = "";


var nome4 = "";
var varia4="";
var ima4 = "";

export default class App extends Component<Props> {





state={
data:[]
}


fetchData = async()=>{
const response = await
fetch('https://www2.multihost.com.br/wp-json/wc/v2/products?consumer_key=ck_ef198ec8b58bc6393aa8b1a16acc02dff3eeb2b0&consumer_secret=cs_7ded4bc722794d5f9f9169ac2232b77f3de8f5e7');

const posts = await response.json();

this.setState({data:posts});

varia = this.state.data[0].id; 
nome = this.state.data[0].name;
ima = this.state.data[0].images[0].src;

varia1 = this.state.data[1].id; 
nome1 = this.state.data[1].name;
ima1 = this.state.data[1].images[0].src;


varia2 = this.state.data[2].id; 
nome2 = this.state.data[2].name;
ima2 = this.state.data[2].images[0].src;


varia3 = this.state.data[3].id; 
nome3 = this.state.data[3].name;
ima3 = this.state.data[3].images[0].src;


varia4 = this.state.data[4].id; 
nome4 = this.state.data[4].name;
ima4 = this.state.data[4].images[0].src;



}

componentDidMount(){
this.fetchData();      

}


render() {



   return (


  <NavigationContainer>
      <Drawer.Navigator initialRouteName="Home" >
       <Drawer.Screen name="Home" component={HomeScreen} />
        <Drawer.Screen name="Notifications" component={NotificationsScreen} />
	<Drawer.Screen name="Promotions"  initialParams={{ arrayItem: this.state.data, valor: joao, valor1: pegar, itemId: 42, itemCor: JSON.stringify(varia)  }} component={PromotionsScreen} />
	<Drawer.Screen name="Contacts" component={ContactsScreen} />
      </Drawer.Navigator>
    </NavigationContainer>




  );
}}
